import React, { useState } from "react"
import { graphql } from "gatsby"
import Layout from "../../components/Layout"
import SEO from "../../components/seo"
import RealisationTitle from "../../components/RealisationTitle"
import { Typography, Grid, Dialog } from "@material-ui/core"
import { Carousel } from "react-responsive-carousel"
import "react-responsive-carousel/lib/styles/carousel.min.css"
import CardInfos from "../../components/CardInfos"
import styled from "styled-components"
import CardInfosProduct from "../../components/CardInfosProduct"
import CardSwissfermetures from "../../components/CardSwissFermetures"
import { realTest } from "../../helpers/realtest"
import { regexOptim } from "../../helpers/regexOptim"

const NewCaroussel = styled(Carousel)`
  & div {
    .control-arrow{
        background:black !important;
      }
    & li {
    background: none !important;
      margin: 0 10px !important;
      & img {
        width: auto !important;
        max-height:100vh !important;
      }
    }
  },
`

const Realisation = ({ data }) => {
  const { strapiRealisation } = data
  if (!strapiRealisation) return null

  const [open, setOpen] = useState({ open: false, img: null })
  const handleImgClick = img => {
    setOpen({ open: !open.open, img: img })
  }
  const dateReal = new Date(realTest.content(strapiRealisation.date))

  const subTitle =
    realTest.childTab(strapiRealisation.produits, "name") +
    " " +
    realTest.child(strapiRealisation.ville, "name") +
    " " +
    dateReal.getMonth() +
    "/" +
    dateReal.getFullYear() +
    " Fiche n° " +
    realTest.content(strapiRealisation.idFiche)
  const optimDesc = regexOptim(realTest.content(strapiRealisation.desc))

  const contentHtml = {
    __html: optimDesc,
  }
  const regexVimeo = /(https:\/\/vimeo.com\/)|(http:\/\/vimeo.com\/)/
  const idVideo = strapiRealisation.video
    ? regexVimeo.test(strapiRealisation.video) &&
      strapiRealisation.video.replace(regexVimeo, "")
    : null
  return (
    <Layout>
      <SEO title={strapiRealisation.name} />
      <RealisationTitle title={strapiRealisation.name} ville={subTitle} />
      <NewCaroussel
        showArrows
        centerMode
        emulateTouch
        infiniteLoop
        dynamicHeight
        centerSlidePercentage={85}
      >
        {strapiRealisation.legacyimage
          ? strapiRealisation.legacyimage.image.map(img => (
              <div
                key={img}
                onClick={() => handleImgClick(img)}
                style={{ cursor: "pointer" }}
              >
                <img
                  src={process.env.SF_LEGACYIMAGES_URL + img}
                  style={{ margin: 0 }}
                />
              </div>
            ))
          : strapiRealisation.photo
          ? strapiRealisation.photo.map(({ url }) => (
              <div
                key={url}
                onClick={() => handleImgClick(url)}
                style={{ cursor: "pointer" }}
              >
                <img src={process.env.STRAPI_URL + url} />
              </div>
            ))
          : null}
      </NewCaroussel>
      <Grid container spacing={2}>
        <Grid item sm={9} xs={12}>
          <Typography variant={"h4"} component={"h2"}>
            {strapiRealisation.name}
          </Typography>
          <Typography variant={"body1"} component={"div"}>
            <div dangerouslySetInnerHTML={contentHtml} />
          </Typography>
          <br />
          <br />
          <CardSwissfermetures />
        </Grid>
        <Grid item sm={3} xs={6}>
          {strapiRealisation.video && (
            <div
              key={strapiRealisation.video}
              onClick={() => handleImgClick(strapiRealisation.video)}
              style={{ cursor: "pointer" }}
            >
              <Typography variant={"h5"} component={"p"} align={"center"}>
                Vidéo
              </Typography>
              <iframe
                src={"https://player.vimeo.com/video/" + idVideo}
                width="100%"
                height="auto"
                frameBorder="0"
                allow="autoplay; fullscreen"
                allowFullScreen
              />
            </div>
          )}
          <CardInfos strapiRealisation={strapiRealisation} />
          <CardInfosProduct
            documents={strapiRealisation.documents}
            productName={
              strapiRealisation.produits &&
              strapiRealisation.produits[0] &&
              strapiRealisation.produits[0].name
            }
          />
        </Grid>
      </Grid>
      <Dialog open={open.open} onClose={() => handleImgClick(null)} fullWidth>
        <img
          onClick={() => handleImgClick(null)}
          src={process.env.SF_LEGACYIMAGES_URL + open.img}
          style={{ margin: 0 }}
        />
      </Dialog>
    </Layout>
  )
}
export default Realisation

export const realisation = graphql`
  query Realisation($url: String!) {
    strapiRealisation(url: { eq: $url }) {
      id
      name
      desc
      idFiche
      date
      matieres {
        name
      }
      documents {
        name
        tag
        link
        media {
          publicURL
        }
      }
      produits {
        name
        ficheproduit
        urlfichier
      }
      isolation
      coloris {
        name
      }
      style
      ville {
        name
      }
      url
      photo {
        url
      }
      legacyimage {
        image
      }
      video
    }
  }
`
