import React from "react"
import { Typography } from "@material-ui/core"
import { Grid } from "@material-ui/core"
import styled from "styled-components"

interface Content {
  title: string
  ville?: string
}

const SfGrid = styled(Grid)`
  margin: 2rem 0 2rem 0;
`

const RealisationTitle = ({ title, ville }: Content) => {
  return (
    <SfGrid container justify={"center"}>
      <Grid item sm={12}>
        <Typography variant={"h3"} component={"h1"} align={"center"}>
          {title}
        </Typography>
        {ville && (
          <>
            <br />
            <Typography variant={"body1"} component={"p"} align={"center"}>
              {ville}
            </Typography>
          </>
        )}
      </Grid>
    </SfGrid>
  )
}

export default RealisationTitle
