import React from "react"
import { Button } from "@material-ui/core"
import { graphql, useStaticQuery } from "gatsby"
import Parameters from "../../helpers/parameters";

const ButtonsContact = () => {
  const { allStrapiParametre } = useStaticQuery(graphql`
    query {
      allStrapiParametre(filter: { optiontype: { eq: "tel" } }) {
        edges {
          node {
            id
            name
            desc
          }
        }
      }
    }
  `)
  return (
    <>
      <Button
        variant={"contained"}
        style={{ background: "#EBAF28" }}
        color={"secondary"}
        href={"tel:" + Parameters.getDesc(allStrapiParametre)}
      >
        Appeler
      </Button>{" "}
      <Button
        variant={"contained"}
        style={{ background: "#2CADE7" }}
        color={"primary"}
        href={"https://swiss-fermetures.ch/"}
      >
        Site
      </Button>
    </>
  )
}
export default ButtonsContact


