import React from "react"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"
import ListItemAvatar from "@material-ui/core/ListItemAvatar"
import Avatar from "@material-ui/core/Avatar"
import ImageIcon from "@material-ui/icons/Image"
import BeachAccessIcon from "@material-ui/icons/BeachAccess"
import PaletteIcon from "@material-ui/icons/Palette"
import styled from "styled-components"

const ListeMarge = styled(List)`
  width: 100%;
  max-width: 360px;
  margin: 15px 0 15px 0;
`

const CardInfos = ({ strapiRealisation }) => {
  return (
    <ListeMarge>
      <ListItem>
        <ListItemAvatar>
          <Avatar>
            <PaletteIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary="Coloris"
          secondary={strapiRealisation.coloris.map(
            couleur => couleur.name + " "
          )}
        />
      </ListItem>
      <ListItem>
        <ListItemAvatar>
          <Avatar>
            <ImageIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary="Style" secondary={strapiRealisation.style} />
      </ListItem>
      <ListItem>
        <ListItemAvatar>
          <Avatar>
            <BeachAccessIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary="Isolé"
          secondary={
            strapiRealisation.isolation === "nonisole"
              ? "Non"
              : strapiRealisation.isolation === "isole"
              ? "Oui"
              : "Autre"
          }
        />
      </ListItem>
    </ListeMarge>
  )
}

export default CardInfos
