import React from "react"
import { Grid, Paper, Typography } from "@material-ui/core"
import Logo from "../Logo"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import ANoStyle from "../styles/ANoStyle"
import ButtonsContact from "../ButtonsContact"
import Parameters from "../../helpers/parameters";

const PaperMarge = styled(Paper)`
  padding: 15px;
`

const CardSwissfermetures = () => {
  const { allStrapiParametre } = useStaticQuery(graphql`
    query {
      allStrapiParametre(
        filter: { optiontype: { in: ["adresse", "site", "mail"] } }
      ) {
        edges {
          node {
            id
            name
            desc
          }
        }
      }
    }
  `)
  return (
    <Grid container spacing={3}>
      <Grid item sm={8} xs={12}>
        <Paper>
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <Logo />
            </Grid>
            <Grid item xs={8}>
              <Typography variant={"h6"} component={"p"}>
                Swiss Fermetures
              </Typography>
              <Typography variant={"body2"} component={"p"}>
                {allStrapiParametre.edges && allStrapiParametre.edges[1] && allStrapiParametre.edges[1].node.desc}
                <br />
                <ANoStyle href={Parameters.getDesc(allStrapiParametre.edges)}>
                  {" "}
                  {Parameters.getDesc(allStrapiParametre.edges)}
                </ANoStyle>
                <br />

                {allStrapiParametre.edges && allStrapiParametre.edges[2] && allStrapiParametre.edges[2].node.desc}
                <br />
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid item sm={4} xs={12}>
        <ButtonsContact />
      </Grid>
    </Grid>
  )
}

export default CardSwissfermetures
