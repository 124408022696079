import React from "react"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"
import ListItemAvatar from "@material-ui/core/ListItemAvatar"
import Avatar from "@material-ui/core/Avatar"
import ImageIcon from "@material-ui/icons/Image"
import BeachAccessIcon from "@material-ui/icons/BeachAccess"
import PaletteIcon from "@material-ui/icons/Palette"
import styled from "styled-components"
import ANoStyle from "../styles/ANoStyle"

const ListeMarge = styled(List)`
  width: 100%;
  max-width: 360px;
`

const CardInfosProduct = ({ documents, productName }) => {
  return (
    <ListeMarge>
      <ListItem>
        <ListItemText primary={productName} />
      </ListItem>
      {documents &&
        documents.map(doc => (
          <>
            {doc.tag === "pdf" && (
              <ANoStyle
                href={doc.media && doc.media.publicURL}
                target={"_blank"}
              >
                <ListItem button>
                  <ListItemAvatar>
                    <Avatar>
                      <PaletteIcon />
                    </Avatar>
                  </ListItemAvatar>

                  <ListItemText primary={"Télécharger le pdf"} />
                </ListItem>
              </ANoStyle>
            )}
            {doc.tag === "fiche" && (
              <ANoStyle href={doc.link} target={"_blank"}>
                <ListItem button>
                  <ListItemAvatar>
                    <Avatar>
                      <ImageIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary="Voir la fiche produit" />
                </ListItem>
              </ANoStyle>
            )}
          </>
        ))}
    </ListeMarge>
  )
}

export default CardInfosProduct
